<template>
  <div class="not-found">
    <p
      class="font-w-b  font-s-72"
      style="color: #333; margin-left:calc(375/1920*100vw);"
    >
      404
    </p>
    <img width="100%" src="../../assets/images/index/404.png" />
    <p class="font-w-b font-s-22" style="color: #252631; text-align: center;">
      没有权限，请联系管理员
    </p>
    <div class="flex flex-j-s-a">
      <button class="font-s-16" @click="back">返回上一页</button>
      <button class="font-s-16" @click="backLogin">返回登录页</button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    back() {
      window.history.go(-1);
    },
    backLogin() {
      this.$router.push({ path: "/" });
    }
  }
};
</script>

<style lang="scss" scoped>
.not-found {
  margin: 0 auto;
  margin-top: calc(120 / 1080 * 100vh);
  /* margin-top: calc(97 / 1080 * 100vh); */
  width: calc(860 / 1920 * 100vw);
  > div {
    margin-top: calc(67 / 1080 * 100vh);
    padding: 0 calc(280 / 1920 * 100vw);
    > button {
      width: calc(113 / 1920 * 100vw);
      height: calc(42 / 1080 * 100vh);
      color: #fff;
      background-color: #3e74b7;
    }
  }
  .font-s-72 {
    font-size: calc(72 / 1920 * 100vw);
  }
}
</style>
